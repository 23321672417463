/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/global.scss"
import "@fortawesome/fontawesome-svg-core/styles.css"

import React from "react"
import { IntersectionObserverProvider } from "./src/provider/IntersectionObserverProvider"

export const wrapRootElement = ({ element }) => (
  <IntersectionObserverProvider>{element}</IntersectionObserverProvider>
)
