exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-result-js": () => import("./../../../src/pages/contact-result.js" /* webpackChunkName: "component---src-pages-contact-result-js" */),
  "component---src-pages-download-index-js": () => import("./../../../src/pages/download/index.js" /* webpackChunkName: "component---src-pages-download-index-js" */),
  "component---src-pages-download-kawaii-character-js": () => import("./../../../src/pages/download/kawaii-character.js" /* webpackChunkName: "component---src-pages-download-kawaii-character-js" */),
  "component---src-pages-download-psychologicalsafety-js": () => import("./../../../src/pages/download/psychologicalsafety.js" /* webpackChunkName: "component---src-pages-download-psychologicalsafety-js" */),
  "component---src-pages-feature-js": () => import("./../../../src/pages/feature.js" /* webpackChunkName: "component---src-pages-feature-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inquiry-js": () => import("./../../../src/pages/inquiry.js" /* webpackChunkName: "component---src-pages-inquiry-js" */),
  "component---src-pages-plan-js": () => import("./../../../src/pages/plan.js" /* webpackChunkName: "component---src-pages-plan-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-term-js": () => import("./../../../src/pages/term.js" /* webpackChunkName: "component---src-pages-term-js" */),
  "component---src-pages-trial-js": () => import("./../../../src/pages/trial.js" /* webpackChunkName: "component---src-pages-trial-js" */),
  "component---src-pages-trial-result-js": () => import("./../../../src/pages/trial-result.js" /* webpackChunkName: "component---src-pages-trial-result-js" */),
  "component---src-pages-usecase-js": () => import("./../../../src/pages/usecase.js" /* webpackChunkName: "component---src-pages-usecase-js" */),
  "component---src-pages-usergide-js": () => import("./../../../src/pages/usergide.js" /* webpackChunkName: "component---src-pages-usergide-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

